<template lang="pug">
include ../Bemto
+b.verification-wrapp: LoadingWrapper(:loading="isLoad"): +e.wrap.container
  +b.P.text.--size-sm.--color-dark-gray(
  ) {{ _('Введіть email та пароль, вказані під час реєстрації') }}
  +e.VALIDATION-OBSERVER.form(
    :style="{marginTop: '12px'}"
    ref='validator'
    v-slot="{ handleSubmit, valid }"
    slim
  ): form(@submit.prevent='handleSubmit(awaitCaptcha)')
    validation-provider(
      vid="recaptcha"
      v-slot="{ errors }"
    )
      captcha.control-descriptor--clear(
        v-model='form.recaptcha'
        ref='captcha'
        @verify='saveCaptcha($event)'
      )
      +b.P.control-descriptor__errors.ma--t-xs.text.--align-center(
        v-if='errors && errors.length'
      ) {{ errors[0] }}
    d-control-input(
      autofocus
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      :rules="'required|email'"
      v-model='form.email'
      type='email'
      name='email'
      :placeholder="_('Email')"
    )
    d-control-input(
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      v-model='form.password'
      name='password'
      type='password'
      :rules="'required'"
      :placeholder="_('Password')"
    )
    +b.P.text.--align-center.--color-red.--size-sm(
      v-if='nonFieldErrors'
    ) {{ nonFieldErrors[0].message }}
    modal-trigger(
      name='ForgotPassword'
      url='modals/ForgotPassword'
      :classes="['vm--modal_sm']"
    )
      template(#default='{ open }')
        +b.P.forgot-password.text.--color-light-blue.--align-center.--size-sm.--pointer-black(
          @click='open'
        ) {{ _('Забули пароль?') }}
    +b.BUTTON.el-btn.--default.text.--space-lg.--color-white.--transform-upper.--weight-bold.sign-btn(
      @click.prevent="awaitCaptcha"
      type='submit'
      :disabled='isLoad'
    ) {{ _('Увійти') }}
    +b.P.social-auth-title.text.--color-black.--align-center.--size-sm {{ _('АБО') }}
      row.ma--t-sm(
        justify='center'
      )
        cell(
          :cols="'12'"
        )
          +b.A.google-button.--google(
            :href='googleLink'
          )
            img(src='/static/img/google.png')
            div.google-text {{ _(' Продовжити з Google') }}
        //- cell(
        //-   :cols="'6'"
        //- )
          +b.A.social-auth.--facebook(
            :href='facebookLink'
          )
            +b.APP-ICON.text.--color-white(
              name="icon-facebook-square"
            )
    div(
      v-for='item in authMessages'
    )
      +b.P.text.--align-center.--color-red.--size-sm {{ item }}
    modal-trigger(
      name='SelectAuthModal'
      url='modals/SelectAuthModal'
      :classes="['vm--modal_xl']"
    )
      template(#default='{ open }')
        +b.P.text.--color-gray.subtitle(@click.prevent='open') {{ _('Немає акаунту? ') }}
            +b.SPAN.text.--color-black {{  _('Створити') }}

</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { signInAPI } from '@requests/services/services'

const Captcha = () => import('@app/Forms/Captcha')

export default {
  name: 'sign-up',
  mixins: [
    FormMixin,
  ],
  components: { Captcha },
  props: {
    regLink: {},
    googleLink: {},
    facebookLink: {},
    authMessages: {
      default: () => ([]),
    },
    nextUrl: String,
  },
  data() {
    return {
      nonFieldErrors: null,
      form: {
        email: null,
        password: null,
      },
      openRegistrationModal: false,
    }
  },
  methods: {
    saveCaptcha(captcha) {
      this.form.recaptcha = captcha
      return this.prepareData()
    },
    updateValidator(errors) {
      this.$refs.captcha.reset()
      this.nonFieldErrors = errors.nonFieldErrors
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    awaitCaptcha() {
      if (this.$refs.captcha) {
        this.$refs.captcha.reload()
      }
    },
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return null;
        return this.submit(valid, this.form)
      });
    },
    send(data) {
      // this.$load(signInAPI.execute({}, data)).then(() => {
      //   this.isLoading = false
      //   window.location.href = redirect
      // })
      //   .catch(() => {
      //     this.isLoading = false
      //   })
      const formdata = JSON.parse(JSON.stringify(data))

      formdata.email = formdata.email.toLowerCase()

      return signInAPI.execute({}, formdata).then(({ data: { item: { redirect } } }) => {
        this.$refs.captcha.reset()
        if (this.nextUrl) {
          window.location.href = this.nextUrl
        } else {
          window.location.href = redirect
        }
      })
      // .catch(() => {
      //   this.isLoading = false
      // })
    },
  },

}
</script>
<style scoped>
.google-text {
  font-weight: 600;
  font-family: Open Sans;
  padding-left: 8px;
  text-decoration: none;
}
.google-button {
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: 2px solid #7069A9;
  justify-content: center;
  height: 40px;
  color: #7069A9;
  transition: all .03s;
}

.google-button:hover {
  border: 2px solid #7069A9;
  color: #7069A9
}

.sign-btn {
  text-transform: none;
  height: 40px;
}

.register-input::placeholder {
  text-align: left;
}
.subtitle {
  color: #141414;
  text-align: center;
  cursor: pointer;
}

.subtitle > span {
  color: #0071c2;
}

.sign-btn:hover {
  background-color: #5CA300;
}

.container {
  margin: 0 16px;
  padding: 40px 24px;
}

</style>
