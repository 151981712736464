import { render, staticRenderFns } from "./TalentDetail.vue?vue&type=template&id=43757508&scoped=true&lang=pug&"
import script from "./TalentDetail.vue?vue&type=script&lang=js&"
export * from "./TalentDetail.vue?vue&type=script&lang=js&"
import style0 from "./TalentDetail.vue?vue&type=style&index=0&id=43757508&prod&scoped=true&lang=scss&"
import style1 from "./TalentDetail.vue?vue&type=style&index=1&id=43757508&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43757508",
  null
  
)

export default component.exports