<template>
  <div class="header-list" v-if="isClient">
    <div class="header-list_item">
      <div class="header-list_image">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="header-list_image">
          <path d="M12.5 21.6496C12.19 21.6496 11.89 21.6096 11.64 21.5196C7.82 20.2096 1.75 15.5596 1.75 8.68961C1.75 5.18961 4.58 2.34961 8.06 2.34961C9.75 2.34961 11.33 3.00961 12.5 4.18961C13.67 3.00961 15.25 2.34961 16.94 2.34961C20.42 2.34961 23.25 5.19961 23.25 8.68961C23.25 15.5696 17.18 20.2096 13.36 21.5196C13.11 21.6096 12.81 21.6496 12.5 21.6496ZM8.06 3.84961C5.41 3.84961 3.25 6.01961 3.25 8.68961C3.25 15.5196 9.82 19.3196 12.13 20.1096C12.31 20.1696 12.7 20.1696 12.88 20.1096C15.18 19.3196 21.76 15.5296 21.76 8.68961C21.76 6.01961 19.6 3.84961 16.95 3.84961C15.43 3.84961 14.02 4.55961 13.11 5.78961C12.83 6.16961 12.19 6.16961 11.91 5.78961C10.98 4.54961 9.58 3.84961 8.06 3.84961Z" fill="#212121"/>
        </svg>
        <div class="number-circle" v-html="favoriteListId.length"></div>
      </div>
      <a href='/cabinet/favorites' class="header-list_link">Обране</a>
    </div>
    <div v-if="isClient">
      <div v-if="projects.length">
        <div class="header-projects_dropdown" @click="toggleOpen">
          <div class="header-projects_text">Мої кастинги</div>
          <div class="header-projects_castings_label-img" :class="{'active': !isOpen}">
            <img  src="/static/img/keyboard_arrow_up.svg" alt="keyboard_arrow_up" width="10" height="6" />
          </div>
        </div>
        <div class="header-projects" v-if="isOpen">
          <div v-for="project in projects" class="header-projects-item" :key="project.id" >
            <a :href="`/cabinet/client/projects-client/${project.id}`" class="link"> {{ project.name }}</a>
          </div>
        </div>
      </div>
      <button :class="['header-list_add', {
          'header-list_add-no-padding': !projects.length
        }]" @click="addCastingClick">
        <img class="header-list_add-icon" src="/static/img/add-icon.svg" width="14" height="14" alt="add-icon" />
        Створити кастинг
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'header-list',
  computed: {
    ...mapGetters('favoriteList', ['getFavoriteList']),
    ...mapState('favoriteList', ['favoriteListId']),
    ...mapActions('favoriteList', [
      'favoriteListIdRequest',
    ]),
    ...mapState('unionProjectsList', ['projects']),
  },
  mounted() {
    // this.favoriteListIdRequest?.()
    this.projectListRequest()
  },
  methods: {
    ...mapActions('unionProjectsList', [
      'projectListRequest',
    ]),
    ...mapActions('unionProjectsList', [
      'projectListRequest',
    ]),
    addCastingClick() {
      window.location.href = '/create-project'
    },
    toggleOpen() {
      console.log('click')
      this.isOpen = !this.isOpen
    },
  },
  data() {
    return {
      isOpen: false,
      isClient: 'client' === window.ROLE,
    }
  },

}
</script>

<style lang="scss" scoped>
.header-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 16px 0;
  align-items: center;

  &_item {
    display: flex;
    align-items: center;
    column-gap: 8px;

    height: 40px;
  }

  &_image {
    position: relative;
    width: 24px;
    height: 24px;
  }

  &_link {
    color: #212121;
    font-size: 14px;
    font-weight: 600;
    &:hover {
      color: #5CA300
    }
  }

  &_add {
    margin-left: 4px;
    margin-top: 16px;

    display: flex;
    align-items: center;
    gap: 8px;

    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;

    color: #63B000;

    padding: 6px 14px;

    border-radius: 40px;
    border: 2px solid #63B000;

    background: transparent;

    &-no-padding {
      margin-top: 0;
    }

    &-icon {
      margin: 5px;
    }
  }
}
.number-circle {
  position: absolute;
  bottom: 17px;
  left: 6px;

  z-index: 999999;

  border-radius: 30px;
  background: #F2F2F2;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 17px;
  height: 17px;

  padding: 4px;

  font-family: Open Sans;
  font-size: 8px;
  font-weight: 600;
  line-height: 8px;
  letter-spacing: -0.30000001192092896px;
  color: black;

  @media (max-width: 1200px) {
    bottom: -6px;
    left: 9px;
  }
}
.active {
  rotate: 180deg;
}

.header-projects {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 8px;
  padding: 0 24px;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &-item {
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_dropdown {
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    justify-content: center;

    height: 40px;
  }

  &_text {
    color: #212121;
    font-size: 14px;
    font-weight: 600;
  }
}

.link {
  color: #0C0C0E;
  font-size: 14px;

  &:hover {
    color: #5CA300
  }
}
</style>
