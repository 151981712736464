<template lang="pug">
include ../../Bemto
ui-click-outside(:do='hideUserBar')
  +b.userbar(
    :class="{'is-active': isActive}"
  )
    +e.heading(
      @click='toggleUserBar'
    )
      +b.user-circle {{modifiedName + modifiedLastName}}
    +e.menu-wrap(
      v-if='isActive'
    )
      +e.menu
        +b.SPAN {{ 'asdasdfasdfasd' }}
</template>

<script>
export default {
  name: 'header-mobile-userbar',
  props: {
    avatar: {},
    role: {},
    cabinet: String,
    logout: String,
    name: String,
    lastName: String,
  },
  data() {
    return {
      isActive: false,
      modifiedLastName: '',
      modifiedName: '',
    }
  },
  mounted() {
    this.modifiedLastName = this.lastName?.[0].toUpperCase()
    this.modifiedName = this.name?.[0].toUpperCase()
  },
  methods: {
    toggleUserBar() {
      this.isActive = !this.isActive
    },
    hideUserBar() {
      this.isActive = false
    },
  },
}
</script>
<style lang="scss" scoped>
.user-circle {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  background: #7069A9;
  border-radius: 30px;
  padding: 8px;

  color: white;

  cursor: pointer;
}

.userbar {
  min-width: unset;
}

.userbar__heading {
  cursor: pointer;
}

.userbar__menu {
  min-width: 240px;
  margin-left: -170px;
}
</style>
