<template>
  <div :class="['success-payment', {
    'success-payment-open': isOpenModal
  }]">
    <div class="success-payment-modal_background"></div>
    <div class="success-payment-modal" v-if="status === 'successful'">
      <div class="success-payment-modal_header">
        <div class="success-payment-modal_header-icon" @click.prevent="closeModal">
          <Cross width="12" height="12"/>
        </div>
      </div>
      <div class="success-payment-modal_img">
        <img src="/static/img/check_circle.svg" alt="check_circle" width="60" height="60" style="margin: 10px" />
      </div>
      <div class="success-payment-modal_title">
        Успішно сплачено!
      </div>
      <div class="success-payment-modal_subtitle">
        Анкети успішно сплачено. Відкриті анкети можна подивитися у особистому кабінеті в розділі на сторінці кастингу або у “Мої відкриті анкети”.
      </div>
    </div>
    <div class="success-payment-modal" v-if="status === 'failed'">
      <div class="success-payment-modal_header">
        <div class="success-payment-modal_header-icon" @click.prevent="closeModal">
          <Cross width="12" height="12"/>
        </div>
      </div>
      <div class="success-payment-modal_img">
        <img src="/static/img/report.svg" width="53" height="53" alt="report" style="margin: 13px"/>
      </div>
      <div class="success-payment-modal_title">
        Оплата не пройшла
      </div>
      <div class="success-payment-modal_subtitle">
        Оплата анкет не пройшла. Перевірте дані картки та спробуйте знову.
      </div>
    </div>
    <div class="success-payment-modal" v-if="isLoading">
      <div class="image-wrap_loader">
        <div class="image-wrap_loader-background">
          <span class="image-wrap_loader_spinner"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cross from '@app/commons/ui-elements/icons/Cross.vue';
import { getOrderStatus } from '@requests/services/services';

export default {
  name: 'success-payment',
  components: { Cross },
  data() {
    return {
      isOpenModal: false,
      isLoading: true,
      status: null,
      secondsCount: 0,
    }
  },
  async mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const urlWithoutQuery = window.location.origin + window.location.pathname;

    const isSuccessPayment = urlParams.get('successPayment')

    let orderId = localStorage.getItem('order_id')

    if (isSuccessPayment && orderId) {
      this.isOpenModal = true
    }

    if (orderId) {
      await getOrderStatus.execute({ id: orderId }).then(res => {
        if ('failure' === res.data.item.order.status) {
          this.isLoading = false
          this.status = 'failed'

          orderId = null

          localStorage.removeItem('order_id')

          window.history.replaceState({}, document.title, urlWithoutQuery);
        }

        if ('success' === res.data.item.order.status) {
          this.isLoading = false
          this.status = 'successful'

          orderId = null

          localStorage.removeItem('order_id')

          window.history.replaceState({}, document.title, urlWithoutQuery);
        }

        if ('pending' === res.data.item.order.status) {
          this.secondsCount += 1

          return
        }

        this.isLoading = false
      })
    }

    if (orderId) {
      const interval = setInterval(() => {
        getOrderStatus.execute({ id: orderId }).then(res => {
          if ('failure' === res.data.item.order.status) {
            this.isLoading = false
            this.status = 'failed'

            orderId = null

            localStorage.removeItem('order_id')

            window.history.replaceState({}, document.title, urlWithoutQuery);
          }

          if ('success' === res.data.item.order.status) {
            this.isLoading = false
            this.status = 'successful'

            orderId = null

            localStorage.removeItem('order_id')

            window.history.replaceState({}, document.title, urlWithoutQuery);
          }

          if ('pending' === res.data.item.order.status) {
            this.secondsCount += 1

            if (10 <= this.secondsCount) {
              this.isLoading = false
              this.status = 'failed'

              orderId = null

              localStorage.removeItem('order_id')

              window.history.replaceState({}, document.title, urlWithoutQuery);

              clearInterval(interval);
            }

            return
          }

          this.isLoading = false

          clearInterval(interval);
        })
      }, 1000)
    }
  },
  methods: {
    closeModal() {
      const urlWithoutQuery = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, urlWithoutQuery);
      this.isOpenModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.image-wrap {
  position: relative;

  &_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 16px;

    @media (max-width: 480px) {
      min-height: 400px;
    }

    &-background {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 70px;
      height: 70px;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.4);
    }

    &_spinner {
      width: 48px;
      height: 48px;
      border: 5px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.success-payment {
  display: none;

  &-open {
    display: block;
  }
}

.success-payment-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 9999;

  width: 494px;
  height: 273px;

  border-radius: 16px;

  background: white;
  box-shadow: 0px 4px 16px 0px #0C0C0E1A;

  @media (max-width: 480px) {
    width: 324px;
    height: fit-content;
  }

  &_background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    z-index: 100;

    background: #00000033;

    @media (max-width: 480px) {
      background: #00000059;
      backdrop-filter: blur(7px)
    }
  }

  &_header {
    display: flex;
    justify-content: end;
    padding: 21.5px 21.5px 13.5px 21.5px;

    &-icon {
      height: 12px;

      cursor: pointer;
    }
  }

  &_img {
    display: flex;
    justify-content: center;
  }

  &_title {
    margin-top: 16px;

    display: flex;
    justify-content: center;

    font-family: Open Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }

  &_subtitle {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;

    padding: 0 40px;
    margin-top: 8px;

    @media (max-width: 480px) {
      margin-bottom: 20px;
    }
  }
}

.el-btn {
  &:hover {
    background: #5CA300;
    color: white;
  }
}
</style>
