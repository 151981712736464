<template>
  <div class="projects-header">
    <button class="projects-header_add" @click="addCastingClick" v-if="!projects.length">
      <img class="projects-header_add-icon" src="/static/img/add-icon.svg" width="14" height="14" alt="add-icon" />
      Створити кастинг
    </button>
    <div class="projects-header_castings" v-else>
      <div class="projects-header_castings_label">
        <v-popover
            :popover-class="'tooltip-modal'"
            :delay="{ show: 100, hide: 100 }"
            @show="onPopoverShow"
            @hide="onPopoverHide"
        >
          <template v-slot:default>
            <div :style="{position: 'relative'}">
              <div class="projects-header_dropdown" @click="toggleOpen">
                <div class="projects-header_castings_label-text">Мої кастинги</div>
                <div class="projects-header_castings_label-img" :class="{'active': !isOpen}">
                  <img  src="/static/img/keyboard_arrow_up.svg" alt="keyboard_arrow_up" width="10" height="6" />
                </div>
              </div>
            </div>
          </template>
          <template v-slot:popover>
            <header-project-bar
                class="card-header-project-bar"
                :projects="projects"
            />
          </template>
        </v-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'projects-header',
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    ...mapActions('unionProjectsList', [
      'projectListRequest',
    ]),
    addCastingClick() {
      window.location.href = '/create-project'
    },

    toggleOpen() {
      this.isOpen = !this.isOpen
    },
    onPopoverShow() {
      this.isOpen = true;
    },

    onPopoverHide() {
      this.isOpen = false;
    },
  },
  computed: {
    ...mapState('unionProjectsList', ['projects']),
  },
  mounted() {
    this.projectListRequest()
  },
}
</script>
<style lang="scss" scoped>
.projects-header {
  &_add {
    margin-left: 4px;

    display: flex;
    align-items: center;
    gap: 8px;

    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;

    color: #63B000;

    padding: 6px 14px;

    border-radius: 40px;
    border: 2px solid #63B000;

    background: transparent;

    &-icon {
      margin: 5px;
    }
  }

  &_castings {
    position: relative;

    &_label {
      display: flex;
      align-items: center;

      padding: 8px 16px;

      cursor: pointer;

      &-text {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;

        color: #212121;
      }

      &-img {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 24px;
        height: 24px;
      }

      &_dropdown {
        position: absolute;
        bottom: -45px;
        right: 20px;

        max-width: 252px;
        width: 100%;

        display: none;

        padding-top: 10px;

        &_content {
          padding: 8px 0;

          background: white;

          border: 1px solid #C8C8D0;
          border-radius: 8px;

          box-shadow: 0px 4px 16px 0px #0C0C0E1A;

          &-divider {
            border-bottom: 1px solid #CCCCCC
          }
        }
      }
    }
  }

  &_dropdown {
    display: flex;
    align-items: center;
    row-gap: 8px;
  }
}

.active {
  rotate: 180deg;
}
</style>
