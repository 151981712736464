import { prefixAPI } from './resources/api'

const ENDPOINTS = {
  //* * Account */
  CURRENT_USER: prefixAPI('accounts/current-user/'),
  AUTH: prefixAPI('accounts/login/'),
  VERIFICATION: prefixAPI('accounts/verification/'),
  CHECK_PASSWORD: prefixAPI('accounts/check-password/'),
  LOGIN: prefixAPI('accounts/login/'),
  REGISTRATION_CLIENT: prefixAPI('accounts/registration/client/'),
  REGISTRATION_CLIENT_FINISH: prefixAPI('accounts/social/client/'),
  CREATE_TALANT: prefixAPI('accounts/registration/talent/'),
  CHANGE_PASSWORD: prefixAPI('accounts/password-change/'),
  RESET_PASSWORD: prefixAPI('accounts/password-reset/'),
  RESET_PASSWORD_CONFIRM: prefixAPI('accounts/password-reset/confirm/'),
  PATCH_ACCOUNT: prefixAPI('accounts/update/'),
  REMOVE_GALLERY_PHOTO: prefixAPI('accounts/photos{/id}/'),
  REMOVE_ACCOUNT: prefixAPI('accounts/remove/'),
  GET_PAID_TALENTS: prefixAPI('accounts/paid-talents/'),
  // * * FEEDBACK API */
  FEEDBACK: prefixAPI('feedback/'),

  //* * CHECK DATA API */
  GENERAL_INFO: prefixAPI('accounts/check-general-info/'),
  VISUAL_DATA: prefixAPI('accounts/check-visual-data/'),
  BODY_DATA: prefixAPI('accounts/check-body-data/'),
  CLOTHES_DATA: prefixAPI('accounts/check-clothes/'),
  MEDIA_DATA: prefixAPI('accounts/check-media/'),

  //* * CHARACTERISTICS API */
  CHARACTERISTICS: prefixAPI('characteristics/'),

  //* * FAVORITES */
  ADD_TO_FAVORITE: prefixAPI('favorites/actions/add/'),
  FAVORITE_LIST: prefixAPI('favorites/list/'),
  FAVORITE_ID_LIST: prefixAPI('favorites/id-list/'),
  COUNTRIES_LIST: prefixAPI('countries/'),
  ADD_TALENTS_FROM_FAVORITE_TO_PROJECT: prefixAPI('favorites/actions/add-to-project/'),
  REMOVE_TALENTS_FROM_FAVORITE: prefixAPI('favorites/actions/remove/{?ids}'),

  //* * PROJECTS API */
  CREATE_PROJECT: prefixAPI('projects/create/'),
  PROJECTS_LIST: prefixAPI('projects/list/'),
  PROJECTS_PREVIEW: prefixAPI('projects/preview-list/'),
  PROJECT_SELECTS: prefixAPI('projects/selects/'),
  ADD_TALENTS_TO_PROJECT: prefixAPI('projects/{id}/actions/add-talents/'),
  REMOVE_TALENTS_FROM_PROJECT: prefixAPI('projects/{id}/actions/remove-talents/'),
  GET_PROJECT: prefixAPI('projects/{id}/receive/'),
  REMOVE_PROJECT: prefixAPI('projects/{id}/remove/'),
  UPDATE_PROJECT: prefixAPI('projects/{id}/update/'),

  PROJECT_REMOVE_EXACT_DATES: prefixAPI('projects/actions/remove-exact-dates{?ids}'),
  PROJECT_REMOVE_REFERENCE_FILES: prefixAPI('projects/actions/remove-reference-files{?ids}'),
  PROJECT_REMOVE_REFERENCE_LINKS: prefixAPI('projects/actions/remove-reference-links{?ids}'),

  PROJECTS_ADD_TO_TALENT: prefixAPI('projects/actions/add-talent-to-projects/{talentId}/'),
  //* * CHARACTERS API */
  BOT_REQUEST: prefixAPI('projects/requests/create/'),

  //* * TALENT */
  GET_TALENT_ID: prefixAPI('talents{/id}/retrieve/'),
  GET_TALENT_PROJECT_PREVIEW_LIST: prefixAPI('talents/talents/project/preview-list'),
  ACCOUNTS_ACTIONS_DELETE_UPLOADED_IMAGE: prefixAPI('accounts/actions/delete-uploaded-image{/id}/'),
  ACCOUNTS_ACTIONS_UPLOAD: prefixAPI('accounts/actions/upload-image/'),
  // * * ADMIN */
  ADMIN_ACTIONS_DELETE_PHOTO: prefixAPI('admin/actions/delete-photo{/id}/'),
  ADMIN_ACTIONS_DELETE_VIDEO: prefixAPI('admin/actions/delete-video{/id}/'),
  ADMIN_ACTIONS_DELETE_UPLOADED_VIDEO: prefixAPI('admin/actions/delete-uploaded-video{/id}/'),
  ADMIN_ACTIONS_UPLOAD_VIDEO: prefixAPI('admin/actions/upload-video/'),
  ADMIN_TALENTS_COUNT: prefixAPI('admin/talents/count/'),
  ADMIN_TALENTS_LIST: prefixAPI('admin/talents/list/{?verified}'),
  ADMIN_TALENTS_UPDATE: prefixAPI('admin/talents{/id}/update/'),
  ADMIN_TALENTS_RETRIEVE: prefixAPI('admin/talents{/id}/retrieve/'),

  SHORT_LIST: prefixAPI('accounts/shortlist/'),
  SHORT_LIST_ADD: prefixAPI('accounts/shortlist/add/'),
  SHORT_LIST_REMOVE: prefixAPI('accounts/shortlist/remove/'),

  BUT_TALENT: prefixAPI('accounts/pay/{?talent_ids}'),
  PASSWORD_RESET: prefixAPI('accounts/password-reset/'),
  PASSWORD_CHANGE: prefixAPI('accounts/password-change/'),
  ACCOUNT_DEACTIVATION: prefixAPI('accounts/toggle-active/'),
  UPDATE_USER: prefixAPI('accounts/update/'),
  ORDER_STATUS: prefixAPI('accounts/order/{id}/'),

  SUBSCRIPTION_BUY: prefixAPI('accounts/create-subscription/'),
  SUBSCRIPTION_CANCEL: prefixAPI('accounts/cancel-subscription/'),
}

export { ENDPOINTS }
