<template lang="pug">
include ../../Bemto
+b
  +b.panel.--bg-white.--br-default.pa--round-sm.panel-wrapper
    row(
      :align="['center']"
      :justify="['between']"
      space='sm'
    )
      cell
        row(
          :align="['end']"
        )
          +b.DIV.icon-wrapper
            star-icon-active(v-if="isActive")
            star-icon(v-else)
          cell
            router-link(
              :to="{ name: PROJECTS_LINK }"
            )
              +b.P.text.--color-black.--size-sm.--weight-bold(:class="{ 'active-tab': isActive }") {{ _('Кастинги') }}
      cell
        +b.total-talents
          +b.SPAN.text--lh-sm.--weight-medium.--color-white.--size-sm {{ projectCount }}
    hr
    +b.DIV.accordion-container
      accordion-component(
        v-for='(accordion, index) in accordionsList'
        :key='`${accordionsList.length}_${index}`'
        :isOpen="checkState(accordion, index)"
        :name="accordion.name"
        :class="[index+1 < accordionsList.length ? 'ma--b-2xs' : '']"
      )
        template(v-slot:content)
          div
            //- hr
            +b.P.text.--size-xs(v-if="!accordion.item.length") {{ _("Список проектів наразі порожній") }}
            div(v-else)
              +b.ROUTER-LINK(
                v-for='(project, idx) in accordion.item'
                :key='`${project.id}_${idx}`'
                :to='{ name: PROJECT_INFO, params: { id: project.id }}'
              )
                +b.P.char-link.text.--size-sm.--color-black.--font-sans {{ project.name }}

  +b.panel.--bg-white.--br-default.panel-wrapper-mobile
    row(
      :align="['center']"
      :justify="['between']"
      space='sm'
      :style="{width: '100%'}"
    )
      cell
        row(
          :align="['end']"
        )
          +b.DIV.icon-wrapper
            star-icon-active(v-if="isActive")
            star-icon(v-else)
          cell
            router-link(
              :to="{ name: PROJECTS_LINK }"
            )
              +b.P.text.--color-black.--size-sm.--weight-bold(:class="{ 'active-tab': isActive }") {{ _('Кастинги') }}
      cell
        +b.total-talents
          +b.SPAN.text--lh-sm.--weight-medium.--color-white.--size-sm {{ projectCount }}
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex'
import { ROUTES_NAMES } from '@routes'
import {
  getTalentProjectPreviewListApi,
} from '@requests/services/services'
import StarIcon from './Icon/StarIcon.vue'
import StarIconActive from './Icon/StartIconActive.vue'

Vue.component('StarIcon', StarIcon);
Vue.component('StaкIconActive', StarIconActive);

export default {
  name: 'projects-accordions',
  components: {
    StarIcon,
    StarIconActive,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    accordionsList: {
      type: Array,
      default: null,
    },
    projectCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      PROJECT_INFO: ROUTES_NAMES.TALENT_PROJECT,
      PROJECTS_LINK: ROUTES_NAMES.TALENT_PROJECTS,
    }
  },
  computed: {
    ...mapGetters('favoriteList', ['getFavoriteList']),

  },
  methods: {
    checkState(accordion) {
      if (Array.isArray(accordion.item)) {
        const projectIds = accordion.item.map(el => el.id)
        if (this.$route.params.id) {
          return projectIds.includes(Number(this.$route.params.id))
        }
        // if (this.$route.query?.status) {
        //   return accordion.status === Number(this.$route.query?.status)
        // }
        // return 0 === index
        return false
      }
      return false
    },
  },
}
</script>
<style scoped>
.accordion-container {
  margin-top: 16px;
}
.panel-wrapper {
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  box-shadow: none;
}

.active-tab {
  color: #5CA300
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vue-accordion__inner > div {
  border-top: none;
  border-bottom: none;
  padding-block-start: 0;
  padding-block-end: 0;
}
.vue-accordion__inner {
  padding-block-start: 0;
}

@media (max-width: 500px) {
  .panel-wrapper {
    display: none;
  }
}
@media (min-width: 501px) {
  .panel-wrapper-mobile {
    display: none;
  }
}

.panel-wrapper-mobile {
  display: flex;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  box-shadow: none;
  padding: 16px 24px;
  height: 72px;
}
</style>
